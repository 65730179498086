@use 'sass:math';

html,
body {
  height: 100svh;
  overflow: hidden;
  font-size: 16px;
}

body {
  margin: 0;
  overflow: hidden;
}

strong {
  font-weight: 600;
}
