@font-face {
    font-family: "BrightlayerUIIcons";
    src: url("./BrightlayerUIIcons.ttf?f5c2986b626cfee68baedd706562e49b") format("truetype"),
url("./BrightlayerUIIcons.woff?f5c2986b626cfee68baedd706562e49b") format("woff"),
url("./BrightlayerUIIcons.woff2?f5c2986b626cfee68baedd706562e49b") format("woff2"),
url("./BrightlayerUIIcons.eot?f5c2986b626cfee68baedd706562e49b#iefix") format("embedded-opentype"),
url("./BrightlayerUIIcons.svg?f5c2986b626cfee68baedd706562e49b#BrightlayerUIIcons") format("svg");
}

i[class^="blui-"]:before, i[class*=" blui-"]:before {
    font-family: BrightlayerUIIcons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.blui-ac:before {
    content: "\f101";
}
.blui-account_settings:before {
    content: "\f102";
}
.blui-air_conditioner:before {
    content: "\f103";
}
.blui-air_filter:before {
    content: "\f104";
}
.blui-amazon_alexa:before {
    content: "\f105";
}
.blui-amazon:before {
    content: "\f106";
}
.blui-ami_gateway:before {
    content: "\f107";
}
.blui-angular:before {
    content: "\f108";
}
.blui-api_settings:before {
    content: "\f109";
}
.blui-api:before {
    content: "\f10a";
}
.blui-apparent_energy:before {
    content: "\f10b";
}
.blui-apparent_power:before {
    content: "\f10c";
}
.blui-apple_logo:before {
    content: "\f10d";
}
.blui-apple:before {
    content: "\f10e";
}
.blui-arc_rated_hood:before {
    content: "\f10f";
}
.blui-arc_rated_suit:before {
    content: "\f110";
}
.blui-asparagus:before {
    content: "\f111";
}
.blui-ats_alt:before {
    content: "\f112";
}
.blui-ats:before {
    content: "\f113";
}
.blui-balaclava:before {
    content: "\f114";
}
.blui-barley_alt:before {
    content: "\f115";
}
.blui-barley:before {
    content: "\f116";
}
.blui-barrel:before {
    content: "\f117";
}
.blui-baseball:before {
    content: "\f118";
}
.blui-basketball_hoop:before {
    content: "\f119";
}
.blui-battery_charging_twotone_05:before {
    content: "\f11a";
}
.blui-battery_charging_twotone_0px:before {
    content: "\f11b";
}
.blui-battery_charging_twotone_10:before {
    content: "\f11c";
}
.blui-battery_charging_twotone_100:before {
    content: "\f11d";
}
.blui-battery_charging_twotone_15:before {
    content: "\f11e";
}
.blui-battery_charging_twotone_20:before {
    content: "\f11f";
}
.blui-battery_charging_twotone_25:before {
    content: "\f120";
}
.blui-battery_charging_twotone_30:before {
    content: "\f121";
}
.blui-battery_charging_twotone_35:before {
    content: "\f122";
}
.blui-battery_charging_twotone_40:before {
    content: "\f123";
}
.blui-battery_charging_twotone_45:before {
    content: "\f124";
}
.blui-battery_charging_twotone_50:before {
    content: "\f125";
}
.blui-battery_charging_twotone_55:before {
    content: "\f126";
}
.blui-battery_charging_twotone_60:before {
    content: "\f127";
}
.blui-battery_charging_twotone_65:before {
    content: "\f128";
}
.blui-battery_charging_twotone_70:before {
    content: "\f129";
}
.blui-battery_charging_twotone_75:before {
    content: "\f12a";
}
.blui-battery_charging_twotone_80:before {
    content: "\f12b";
}
.blui-battery_charging_twotone_85:before {
    content: "\f12c";
}
.blui-battery_charging_twotone_90:before {
    content: "\f12d";
}
.blui-battery_charging_twotone_95:before {
    content: "\f12e";
}
.blui-battery_disabled_alt:before {
    content: "\f12f";
}
.blui-battery_disabled:before {
    content: "\f130";
}
.blui-battery_large_outline:before {
    content: "\f131";
}
.blui-battery_large_reset_outline:before {
    content: "\f132";
}
.blui-battery_large_reset:before {
    content: "\f133";
}
.blui-battery_large_showing_terminals:before {
    content: "\f134";
}
.blui-battery_large:before {
    content: "\f135";
}
.blui-battery_twotone_0:before {
    content: "\f136";
}
.blui-battery_twotone_05:before {
    content: "\f137";
}
.blui-battery_twotone_10:before {
    content: "\f138";
}
.blui-battery_twotone_100:before {
    content: "\f139";
}
.blui-battery_twotone_15:before {
    content: "\f13a";
}
.blui-battery_twotone_20:before {
    content: "\f13b";
}
.blui-battery_twotone_25:before {
    content: "\f13c";
}
.blui-battery_twotone_30:before {
    content: "\f13d";
}
.blui-battery_twotone_35:before {
    content: "\f13e";
}
.blui-battery_twotone_40:before {
    content: "\f13f";
}
.blui-battery_twotone_45:before {
    content: "\f140";
}
.blui-battery_twotone_50:before {
    content: "\f141";
}
.blui-battery_twotone_55:before {
    content: "\f142";
}
.blui-battery_twotone_60:before {
    content: "\f143";
}
.blui-battery_twotone_65:before {
    content: "\f144";
}
.blui-battery_twotone_70:before {
    content: "\f145";
}
.blui-battery_twotone_75:before {
    content: "\f146";
}
.blui-battery_twotone_80:before {
    content: "\f147";
}
.blui-battery_twotone_85:before {
    content: "\f148";
}
.blui-battery_twotone_90:before {
    content: "\f149";
}
.blui-battery_twotone_95:before {
    content: "\f14a";
}
.blui-battery:before {
    content: "\f14b";
}
.blui-bearing:before {
    content: "\f14c";
}
.blui-blast:before {
    content: "\f14d";
}
.blui-bolt_off:before {
    content: "\f14e";
}
.blui-box_cutter:before {
    content: "\f14f";
}
.blui-boxing_glove:before {
    content: "\f150";
}
.blui-breaker_closed:before {
    content: "\f151";
}
.blui-breaker:before {
    content: "\f152";
}
.blui-broccoli:before {
    content: "\f153";
}
.blui-building:before {
    content: "\f154";
}
.blui-bypass_alt:before {
    content: "\f155";
}
.blui-bypass_battery:before {
    content: "\f156";
}
.blui-bypass:before {
    content: "\f157";
}
.blui-camera:before {
    content: "\f158";
}
.blui-capacitor_large:before {
    content: "\f159";
}
.blui-capacitor_symbol:before {
    content: "\f15a";
}
.blui-car:before {
    content: "\f15b";
}
.blui-carrot:before {
    content: "\f15c";
}
.blui-ceiling_light:before {
    content: "\f15d";
}
.blui-champ_vmvl:before {
    content: "\f15e";
}
.blui-chili_pepper:before {
    content: "\f15f";
}
.blui-cloud_done:before {
    content: "\f160";
}
.blui-cloud_off_filled:before {
    content: "\f161";
}
.blui-combine_harvester:before {
    content: "\f162";
}
.blui-computer:before {
    content: "\f163";
}
.blui-configuration:before {
    content: "\f164";
}
.blui-contactor:before {
    content: "\f165";
}
.blui-control:before {
    content: "\f166";
}
.blui-cooking:before {
    content: "\f167";
}
.blui-corn:before {
    content: "\f168";
}
.blui-current_ac:before {
    content: "\f169";
}
.blui-current_circled_outline:before {
    content: "\f16a";
}
.blui-current_circled:before {
    content: "\f16b";
}
.blui-current_dc:before {
    content: "\f16c";
}
.blui-current:before {
    content: "\f16d";
}
.blui-cybersecurity_two_tone:before {
    content: "\f16e";
}
.blui-cybersecurity:before {
    content: "\f16f";
}
.blui-database:before {
    content: "\f170";
}
.blui-dc:before {
    content: "\f171";
}
.blui-department:before {
    content: "\f172";
}
.blui-departments:before {
    content: "\f173";
}
.blui-device_activating:before {
    content: "\f174";
}
.blui-device_configuration:before {
    content: "\f175";
}
.blui-device_info:before {
    content: "\f176";
}
.blui-device_with_keypad:before {
    content: "\f177";
}
.blui-device:before {
    content: "\f178";
}
.blui-diagnostic_alt:before {
    content: "\f179";
}
.blui-diagnostic:before {
    content: "\f17a";
}
.blui-dimmer:before {
    content: "\f17b";
}
.blui-diode:before {
    content: "\f17c";
}
.blui-distance_apart:before {
    content: "\f17d";
}
.blui-distribution_box_outline:before {
    content: "\f17e";
}
.blui-distribution_box:before {
    content: "\f17f";
}
.blui-double_triangle:before {
    content: "\f180";
}
.blui-drive:before {
    content: "\f181";
}
.blui-eaton_tagline:before {
    content: "\f182";
}
.blui-eaton_two_tone:before {
    content: "\f183";
}
.blui-eaton:before {
    content: "\f184";
}
.blui-environment:before {
    content: "\f185";
}
.blui-ephesus_fixture:before {
    content: "\f186";
}
.blui-ephesus_gateway:before {
    content: "\f187";
}
.blui-ethernet_port:before {
    content: "\f188";
}
.blui-ev_plug_ccs_combo:before {
    content: "\f189";
}
.blui-ev_plug_chademo:before {
    content: "\f18a";
}
.blui-ev_plug_domestic_a:before {
    content: "\f18b";
}
.blui-ev_plug_domestic_b:before {
    content: "\f18c";
}
.blui-ev_plug_domestic_c:before {
    content: "\f18d";
}
.blui-ev_plug_domestic_d:before {
    content: "\f18e";
}
.blui-ev_plug_domestic_e:before {
    content: "\f18f";
}
.blui-ev_plug_domestic_f:before {
    content: "\f190";
}
.blui-ev_plug_domestic_g:before {
    content: "\f191";
}
.blui-ev_plug_domestic_h:before {
    content: "\f192";
}
.blui-ev_plug_domestic_i:before {
    content: "\f193";
}
.blui-ev_plug_domestic_j:before {
    content: "\f194";
}
.blui-ev_plug_domestic_k:before {
    content: "\f195";
}
.blui-ev_plug_domestic_l:before {
    content: "\f196";
}
.blui-ev_plug_iec_60309_2_single_16:before {
    content: "\f197";
}
.blui-ev_plug_iec_60309_2_three_16:before {
    content: "\f198";
}
.blui-ev_plug_iec_60309_2_three_32:before {
    content: "\f199";
}
.blui-ev_plug_iec_60309_2_three_64:before {
    content: "\f19a";
}
.blui-ev_plug_iec_61851_gbt:before {
    content: "\f19b";
}
.blui-ev_plug_iec_62196_t1_combo:before {
    content: "\f19c";
}
.blui-ev_plug_iec_62196_t1:before {
    content: "\f19d";
}
.blui-ev_plug_iec_62196_t2_combo:before {
    content: "\f19e";
}
.blui-ev_plug_iec_62196_t2:before {
    content: "\f19f";
}
.blui-ev_plug_iec_62196_t3a:before {
    content: "\f1a0";
}
.blui-ev_plug_iec_62196_t3c:before {
    content: "\f1a1";
}
.blui-ev_plug_tesla_r:before {
    content: "\f1a2";
}
.blui-ev_plug_tesla_s:before {
    content: "\f1a3";
}
.blui-ev_plug_type_one:before {
    content: "\f1a4";
}
.blui-ev_plug_type_two:before {
    content: "\f1a5";
}
.blui-face_id:before {
    content: "\f1a6";
}
.blui-factory:before {
    content: "\f1a7";
}
.blui-fan_circled:before {
    content: "\f1a8";
}
.blui-fan:before {
    content: "\f1a9";
}
.blui-file_csv:before {
    content: "\f1aa";
}
.blui-file_doc:before {
    content: "\f1ab";
}
.blui-file_pdf:before {
    content: "\f1ac";
}
.blui-flask:before {
    content: "\f1ad";
}
.blui-flood:before {
    content: "\f1ae";
}
.blui-floor_plan:before {
    content: "\f1af";
}
.blui-flow:before {
    content: "\f1b0";
}
.blui-flower_tulip:before {
    content: "\f1b1";
}
.blui-flower:before {
    content: "\f1b2";
}
.blui-football_helmet:before {
    content: "\f1b3";
}
.blui-forklift:before {
    content: "\f1b4";
}
.blui-frequency:before {
    content: "\f1b5";
}
.blui-fuel:before {
    content: "\f1b6";
}
.blui-garage_closed:before {
    content: "\f1b7";
}
.blui-garlic:before {
    content: "\f1b8";
}
.blui-gas_cylinder:before {
    content: "\f1b9";
}
.blui-generator_off:before {
    content: "\f1ba";
}
.blui-generator:before {
    content: "\f1bb";
}
.blui-github:before {
    content: "\f1bc";
}
.blui-glass_divider:before {
    content: "\f1bd";
}
.blui-glasses:before {
    content: "\f1be";
}
.blui-glove_alt:before {
    content: "\f1bf";
}
.blui-glove:before {
    content: "\f1c0";
}
.blui-google:before {
    content: "\f1c1";
}
.blui-grade_a:before {
    content: "\f1c2";
}
.blui-grade_b:before {
    content: "\f1c3";
}
.blui-grade_c:before {
    content: "\f1c4";
}
.blui-grade_d:before {
    content: "\f1c5";
}
.blui-grade_f:before {
    content: "\f1c6";
}
.blui-grass:before {
    content: "\f1c7";
}
.blui-hammer:before {
    content: "\f1c8";
}
.blui-hard_hat:before {
    content: "\f1c9";
}
.blui-harmonics:before {
    content: "\f1ca";
}
.blui-health:before {
    content: "\f1cb";
}
.blui-hearing_protection:before {
    content: "\f1cc";
}
.blui-heat_shrink_tube:before {
    content: "\f1cd";
}
.blui-help_desk_log:before {
    content: "\f1ce";
}
.blui-hockey_puck:before {
    content: "\f1cf";
}
.blui-hockey_sticks:before {
    content: "\f1d0";
}
.blui-home_repair_service_outline:before {
    content: "\f1d1";
}
.blui-hops:before {
    content: "\f1d2";
}
.blui-hubject:before {
    content: "\f1d3";
}
.blui-incident_active:before {
    content: "\f1d4";
}
.blui-incident_maintenance:before {
    content: "\f1d5";
}
.blui-incident_resolved:before {
    content: "\f1d6";
}
.blui-incident:before {
    content: "\f1d7";
}
.blui-input_flow:before {
    content: "\f1d8";
}
.blui-insulated_glove_alt:before {
    content: "\f1d9";
}
.blui-insulated_glove:before {
    content: "\f1da";
}
.blui-internet:before {
    content: "\f1db";
}
.blui-iot_scene:before {
    content: "\f1dc";
}
.blui-ippe_vibrate_off:before {
    content: "\f1dd";
}
.blui-ippe_vibrate_outlined:before {
    content: "\f1de";
}
.blui-ippe_vibrate:before {
    content: "\f1df";
}
.blui-leaf:before {
    content: "\f1e0";
}
.blui-left_outline:before {
    content: "\f1e1";
}
.blui-left:before {
    content: "\f1e2";
}
.blui-light_bulb:before {
    content: "\f1e3";
}
.blui-light_switch:before {
    content: "\f1e4";
}
.blui-line_to_line:before {
    content: "\f1e5";
}
.blui-line_to_neutral:before {
    content: "\f1e6";
}
.blui-load_factor:before {
    content: "\f1e7";
}
.blui-lock_reset:before {
    content: "\f1e8";
}
.blui-maintenance:before {
    content: "\f1e9";
}
.blui-map_marker_multiple:before {
    content: "\f1ea";
}
.blui-map_marker_three:before {
    content: "\f1eb";
}
.blui-meter_face:before {
    content: "\f1ec";
}
.blui-moisture:before {
    content: "\f1ed";
}
.blui-motor_alt:before {
    content: "\f1ee";
}
.blui-motor_controller_outline:before {
    content: "\f1ef";
}
.blui-motor_controller:before {
    content: "\f1f0";
}
.blui-motor_outline:before {
    content: "\f1f1";
}
.blui-motor_starter:before {
    content: "\f1f2";
}
.blui-motor:before {
    content: "\f1f3";
}
.blui-multimeter_outline:before {
    content: "\f1f4";
}
.blui-multimeter:before {
    content: "\f1f5";
}
.blui-network_switch:before {
    content: "\f1f6";
}
.blui-notification_log:before {
    content: "\f1f7";
}
.blui-notifications_active_outline:before {
    content: "\f1f8";
}
.blui-outlet_alt_off:before {
    content: "\f1f9";
}
.blui-outlet_alt:before {
    content: "\f1fa";
}
.blui-outlet_c14_off:before {
    content: "\f1fb";
}
.blui-outlet_c14:before {
    content: "\f1fc";
}
.blui-outlet_c19_off:before {
    content: "\f1fd";
}
.blui-outlet_c19:before {
    content: "\f1fe";
}
.blui-outlet_c39_off:before {
    content: "\f1ff";
}
.blui-outlet_c39:before {
    content: "\f200";
}
.blui-outlet:before {
    content: "\f201";
}
.blui-outline_offline_bolt:before {
    content: "\f202";
}
.blui-output_flow:before {
    content: "\f203";
}
.blui-overalls:before {
    content: "\f204";
}
.blui-overlap:before {
    content: "\f205";
}
.blui-panel_log:before {
    content: "\f206";
}
.blui-pantograph_bottom_up:before {
    content: "\f207";
}
.blui-pantograph_top_down:before {
    content: "\f208";
}
.blui-paywall:before {
    content: "\f209";
}
.blui-pdu:before {
    content: "\f20a";
}
.blui-pepper:before {
    content: "\f20b";
}
.blui-phase_angle:before {
    content: "\f20c";
}
.blui-pickup_truck:before {
    content: "\f20d";
}
.blui-pie_chart_0:before {
    content: "\f20e";
}
.blui-pie_chart_05:before {
    content: "\f20f";
}
.blui-pie_chart_10:before {
    content: "\f210";
}
.blui-pie_chart_100:before {
    content: "\f211";
}
.blui-pie_chart_15:before {
    content: "\f212";
}
.blui-pie_chart_20:before {
    content: "\f213";
}
.blui-pie_chart_25:before {
    content: "\f214";
}
.blui-pie_chart_30:before {
    content: "\f215";
}
.blui-pie_chart_35:before {
    content: "\f216";
}
.blui-pie_chart_40:before {
    content: "\f217";
}
.blui-pie_chart_45:before {
    content: "\f218";
}
.blui-pie_chart_50:before {
    content: "\f219";
}
.blui-pie_chart_55:before {
    content: "\f21a";
}
.blui-pie_chart_60:before {
    content: "\f21b";
}
.blui-pie_chart_65:before {
    content: "\f21c";
}
.blui-pie_chart_70:before {
    content: "\f21d";
}
.blui-pie_chart_75:before {
    content: "\f21e";
}
.blui-pie_chart_80:before {
    content: "\f21f";
}
.blui-pie_chart_85:before {
    content: "\f220";
}
.blui-pie_chart_90:before {
    content: "\f221";
}
.blui-pie_chart_95:before {
    content: "\f222";
}
.blui-pie_chart_twotone_0:before {
    content: "\f223";
}
.blui-pie_chart_twotone_05:before {
    content: "\f224";
}
.blui-pie_chart_twotone_10:before {
    content: "\f225";
}
.blui-pie_chart_twotone_100:before {
    content: "\f226";
}
.blui-pie_chart_twotone_15:before {
    content: "\f227";
}
.blui-pie_chart_twotone_20:before {
    content: "\f228";
}
.blui-pie_chart_twotone_25:before {
    content: "\f229";
}
.blui-pie_chart_twotone_30:before {
    content: "\f22a";
}
.blui-pie_chart_twotone_35:before {
    content: "\f22b";
}
.blui-pie_chart_twotone_40:before {
    content: "\f22c";
}
.blui-pie_chart_twotone_45:before {
    content: "\f22d";
}
.blui-pie_chart_twotone_50:before {
    content: "\f22e";
}
.blui-pie_chart_twotone_55:before {
    content: "\f22f";
}
.blui-pie_chart_twotone_60:before {
    content: "\f230";
}
.blui-pie_chart_twotone_65:before {
    content: "\f231";
}
.blui-pie_chart_twotone_70:before {
    content: "\f232";
}
.blui-pie_chart_twotone_75:before {
    content: "\f233";
}
.blui-pie_chart_twotone_80:before {
    content: "\f234";
}
.blui-pie_chart_twotone_85:before {
    content: "\f235";
}
.blui-pie_chart_twotone_90:before {
    content: "\f236";
}
.blui-pie_chart_twotone_95:before {
    content: "\f237";
}
.blui-piggy_bank:before {
    content: "\f238";
}
.blui-pipe_disconnected:before {
    content: "\f239";
}
.blui-pipe_leak:before {
    content: "\f23a";
}
.blui-pipe:before {
    content: "\f23b";
}
.blui-pliers:before {
    content: "\f23c";
}
.blui-potato:before {
    content: "\f23d";
}
.blui-power_ac:before {
    content: "\f23e";
}
.blui-power_apparent_ac:before {
    content: "\f23f";
}
.blui-power_circled_outline:before {
    content: "\f240";
}
.blui-power_circled:before {
    content: "\f241";
}
.blui-power_dc:before {
    content: "\f242";
}
.blui-power_factor:before {
    content: "\f243";
}
.blui-power_redundancy_off:before {
    content: "\f244";
}
.blui-power_redundancy:before {
    content: "\f245";
}
.blui-ppe_level_two_tone:before {
    content: "\f246";
}
.blui-ppe_level:before {
    content: "\f247";
}
.blui-pressure_alt:before {
    content: "\f248";
}
.blui-pressure:before {
    content: "\f249";
}
.blui-priority:before {
    content: "\f24a";
}
.blui-protocol:before {
    content: "\f24b";
}
.blui-pump:before {
    content: "\f24c";
}
.blui-push_notification_solid:before {
    content: "\f24d";
}
.blui-push_notification:before {
    content: "\f24e";
}
.blui-pxblue_small_alt:before {
    content: "\f24f";
}
.blui-pxblue_small:before {
    content: "\f250";
}
.blui-pxblue:before {
    content: "\f251";
}
.blui-pxgreen:before {
    content: "\f252";
}
.blui-pxred:before {
    content: "\f253";
}
.blui-pxwhite:before {
    content: "\f254";
}
.blui-qrcode:before {
    content: "\f255";
}
.blui-radio_handheld:before {
    content: "\f256";
}
.blui-react:before {
    content: "\f257";
}
.blui-reactive_energy:before {
    content: "\f258";
}
.blui-reactive_power:before {
    content: "\f259";
}
.blui-real_energy:before {
    content: "\f25a";
}
.blui-real_power:before {
    content: "\f25b";
}
.blui-relay:before {
    content: "\f25c";
}
.blui-rfid:before {
    content: "\f25d";
}
.blui-right_outline:before {
    content: "\f25e";
}
.blui-right:before {
    content: "\f25f";
}
.blui-road_alt:before {
    content: "\f260";
}
.blui-road:before {
    content: "\f261";
}
.blui-running_high:before {
    content: "\f262";
}
.blui-running_low:before {
    content: "\f263";
}
.blui-safety_boot:before {
    content: "\f264";
}
.blui-safety_glasses:before {
    content: "\f265";
}
.blui-satellite_uplink:before {
    content: "\f266";
}
.blui-scan_qrcode:before {
    content: "\f267";
}
.blui-screw_head_phillips:before {
    content: "\f268";
}
.blui-screw_head_slotted:before {
    content: "\f269";
}
.blui-screwdriver:before {
    content: "\f26a";
}
.blui-sensor_alt:before {
    content: "\f26b";
}
.blui-sensor_device_alt:before {
    content: "\f26c";
}
.blui-sensor_device:before {
    content: "\f26d";
}
.blui-sensor:before {
    content: "\f26e";
}
.blui-sequence:before {
    content: "\f26f";
}
.blui-server:before {
    content: "\f270";
}
.blui-servers:before {
    content: "\f271";
}
.blui-sign_caution:before {
    content: "\f272";
}
.blui-silo:before {
    content: "\f273";
}
.blui-sitemap_outline:before {
    content: "\f274";
}
.blui-skillet_cooktop:before {
    content: "\f275";
}
.blui-skillet:before {
    content: "\f276";
}
.blui-smoke_detector:before {
    content: "\f277";
}
.blui-soybean:before {
    content: "\f278";
}
.blui-sports_car:before {
    content: "\f279";
}
.blui-ssol:before {
    content: "\f27a";
}
.blui-stockpot:before {
    content: "\f27b";
}
.blui-stopped:before {
    content: "\f27c";
}
.blui-stopping:before {
    content: "\f27d";
}
.blui-stove:before {
    content: "\f27e";
}
.blui-studioblue_outline:before {
    content: "\f27f";
}
.blui-studioblue:before {
    content: "\f280";
}
.blui-sunflower:before {
    content: "\f281";
}
.blui-support:before {
    content: "\f282";
}
.blui-switch:before {
    content: "\f283";
}
.blui-switchgear:before {
    content: "\f284";
}
.blui-technician:before {
    content: "\f285";
}
.blui-temp:before {
    content: "\f286";
}
.blui-tennis:before {
    content: "\f287";
}
.blui-toggle_off_outlined:before {
    content: "\f288";
}
.blui-toggle_off:before {
    content: "\f289";
}
.blui-toggle_on_outlined:before {
    content: "\f28a";
}
.blui-toggle_on:before {
    content: "\f28b";
}
.blui-tomato:before {
    content: "\f28c";
}
.blui-tractor:before {
    content: "\f28d";
}
.blui-traffic_light:before {
    content: "\f28e";
}
.blui-transformer:before {
    content: "\f28f";
}
.blui-tree:before {
    content: "\f290";
}
.blui-trending_down:before {
    content: "\f291";
}
.blui-trending_flat:before {
    content: "\f292";
}
.blui-trending_up:before {
    content: "\f293";
}
.blui-trip_curve_alt:before {
    content: "\f294";
}
.blui-trip_curve:before {
    content: "\f295";
}
.blui-trip_unit:before {
    content: "\f296";
}
.blui-tripped:before {
    content: "\f297";
}
.blui-tv_remote:before {
    content: "\f298";
}
.blui-ups_outline:before {
    content: "\f299";
}
.blui-ups:before {
    content: "\f29a";
}
.blui-utility_disabled:before {
    content: "\f29b";
}
.blui-utility_van:before {
    content: "\f29c";
}
.blui-utility:before {
    content: "\f29d";
}
.blui-vfd:before {
    content: "\f29e";
}
.blui-video_alert_outline:before {
    content: "\f29f";
}
.blui-video_alert:before {
    content: "\f2a0";
}
.blui-video_streaming_outline:before {
    content: "\f2a1";
}
.blui-video_streaming:before {
    content: "\f2a2";
}
.blui-vmms:before {
    content: "\f2a3";
}
.blui-voltage_ac:before {
    content: "\f2a4";
}
.blui-voltage_circled_outline:before {
    content: "\f2a5";
}
.blui-voltage_circled:before {
    content: "\f2a6";
}
.blui-voltage_dc:before {
    content: "\f2a7";
}
.blui-voltage:before {
    content: "\f2a8";
}
.blui-warehouse_alt:before {
    content: "\f2a9";
}
.blui-water_energy:before {
    content: "\f2aa";
}
.blui-water_outline:before {
    content: "\f2ab";
}
.blui-water:before {
    content: "\f2ac";
}
.blui-waveform:before {
    content: "\f2ad";
}
.blui-wheat:before {
    content: "\f2ae";
}
.blui-wind_turbine:before {
    content: "\f2af";
}
.blui-xstorage_alt:before {
    content: "\f2b0";
}
.blui-xstorage:before {
    content: "\f2b1";
}
